import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomAlert from '@/app/components/shared/Alerts/CustomAlert';
import { Form, FormGroup } from 'reactstrap';
import InputController from '@/app/components/shared/Forms/InputController';
import Button from '@/app/components/shared/Buttons/Button';
import { AlertTypeEnum, ColorTypeEnum, QueryKey, SizeEnum } from '@/app/utils/constants';
import CustomLink from '@/app/components/navigation/CustomLink';
import { useRouter, useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import routes from '@/app/utils/routes';
import { useTranslations } from 'next-intl';
import useTracking from '@/app/hooks/useTracking';
import { addQueryParameterToRoute } from '@/app/utils/helpers';
import { NavEventProperties, EventOutcomes, UserEventProperties } from '@/app/utils/trackingEvents';
import { getRetailerMappingByHostname } from '@/app/services/retailerService';

type Inputs = {
  username: string;
  password: string;
};

export default function LoginForm() {
  const t = useTranslations('pages.accountManagement.loginPage');
  const schema = yup.object({
    username: yup
      .string()
      .strongEmailValidator(t('validation.emailValid'))
      .required(t('validation.emailRequired')),
    password: yup.string().required(t('validation.passwordRequired')),
  });

  const methods = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const params = useSearchParams();
  const redirectionTarget = params.get('target');
  const paymentIds = params.get('paymentIds') ?? '';
  const { control, handleSubmit, clearErrors, reset } = methods;
  const router = useRouter();
  const [error, setError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { events, trackEvent, trackClick } = useTracking();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const trackingProps: UserEventProperties = {
      component: 'LoginForm',
      outcome: EventOutcomes.Attempt,
    };
    trackClick(events.USER_LOGIN, trackingProps);
    setError(false);
    setIsLoading(true);
    const res = await signIn('credentials', {
      userName: data.username,
      password: data.password,
      retailerId: getRetailerMappingByHostname(window.location.hostname).retailerId,
      redirect: false,
    });
    setIsLoading(false);
    if (res?.error) {
      const trackingProps: UserEventProperties = {
        component: 'LoginForm',
        outcome: EventOutcomes.Failure,
      };
      trackEvent(events.USER_LOGIN, trackingProps);
      setError(true);
    } else {
      /*
      Redirect to the default page after successful login
       */
      const trackingProps: UserEventProperties = {
        component: 'LoginForm',
        outcome: EventOutcomes.Success,
      };
      trackEvent(events.USER_LOGIN, trackingProps);
      let redirectRoute = redirectionTarget ? `/${redirectionTarget}` : routes.defaultRoute;
      redirectRoute = addQueryParameterToRoute(redirectRoute, 'sign-now', 'paymentIds', paymentIds);
      router.push(redirectRoute);
    }
  };

  return (
    <>
      {error && (
        <div className="mb-3">
          <CustomAlert type={AlertTypeEnum.DANGER} title={t('errors.invalidEmailOrPassword')}>
            {t('errors.invalidEmailOrPasswordMessage')}
          </CustomAlert>
        </div>
      )}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormGroup className="default-form-group">
            <InputController label={t('formLabels.email')} name="username" type="text" />
          </FormGroup>
          <FormGroup className="default-form-group">
            <InputController label={t('formLabels.password')} name="password" type="password" />
          </FormGroup>
          <Button
            isLoading={isLoading}
            color={ColorTypeEnum.PRIMARY}
            size={SizeEnum.MD}
            className={'w-100 mt-2'}
          >
            {t('buttons.signIn')}
          </Button>
          <div className="d-flex justify-content-end mt-4">
            <CustomLink
              href={routes.resetPassword}
              onClick={() => {
                const trackingProps: NavEventProperties = {
                  component: 'LoginForm',
                  target: routes.resetPassword,
                };
                trackClick(events.NAV_CLICKED, trackingProps);
              }}
            >
              {t('links.forgotPassword')}
            </CustomLink>
          </div>
        </Form>
      </FormProvider>
    </>
  );
}
